.eyecatcher-start {
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width:992px) {
		flex-direction: column;
	}


	.eyecatcher-col {
		background: #f7f6f4;
		width: 33.33%;
		display: flex;
		align-items: center;
		text-align: left;
		align-items: center;
		padding: 15px;
		box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);

		@media (max-width:992px) {
			width: 100%;
		}

		img {
			margin-right: 15px;
		}

		p {
			margin-bottom: 0;
		}
	}
}