.buchungshotline-v1 {
	background: var(--color-brown);

	.buchungshotline-bl {
		display: flex;

		@media (max-width:767px) {
			flex-direction: column;
		}

		.col-left {
			width: 250px;
			margin-right: 50px;

			@media (max-width:767px) {
				width: 100%;
				margin-right: 0;

			}


		}

		.col-right {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.title {
				font-size: 27px;
				line-height: 31px;
				margin-bottom: 25px;
				color: var(--color-secondary);
				font-weight: 700;
				text-transform: uppercase;
			}

			.hotline {
				font-size: 30px;
				font-weight: 700;
				color: var(--color-primary);

				font-family: var(--title-font-family-main);
			}
		}

		.Buchungshotline-slider {
			.item {
				height: 250px;
				width: 250px;
				border-radius: 50%;
				overflow: hidden;
				border: 8px solid #fff;

				@media (max-width:767px) {
					margin-bottom: 20px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}