@media (min-width:1201px) {

	.navbar-v1 .header-main .main-menu>ul>li>a,
	.navbar-v1 .nav-row .col-12 .icons-nav li a {
		padding: 40px 1vW !important;
		transition: all .2s;
	}

	.navbar-v1 .header-main .header-logo .logo {
		width: 300px !important;
		transition: all .2s;
	}

	.navbar-v1 .fav-count .sub-count {
		top: 35px !important;
	}

	.navbar-v1 .nav-row .col-12 .icons-nav li a:hover {
		background-color: transparent !important;
	}

	.navbar-v1.affix .fav-count .sub-count {
		top: 15px !important;
	}


	.navbar-v1.affix .header-main .header-logo .logo {
		width: 250px !important;

	}

	.navbar-v1.affix .header-main .main-menu>ul>li>a,
	.navbar-v1.affix .nav-row .col-12 .icons-nav li a {
		padding: 22px 1.2vW !important;
	}

}

.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:1200px) {
		position: absolute;
	}

	.fav-count {
		a {
			position: relative;
		}

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 15px;
			left: 30px;
			width: 18px;
			height: 18px;
			background: var(--color-secondary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;

			@media (max-width:1200px) {
				left: 17px;
			}

			@media (max-width:767px) {
				left: 10px;
			}
		}
	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				@media (max-width:767px) {
					margin: 0 10px;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}



	}

	.header-main {

		.header-logo {
			position: absolute;
			top: 5px;
			z-index: 20;

			@media (max-width:1200px) {
				top: 0;

			}

			.logo {
				width: 260px;
				height: auto;

				@media (max-width:1200px) {
					width: 200px;
				}

			}

		}

		.main-menu {
			float: right;

			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						padding: 22px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 700;
						font-size: 1.0625rem;
						font-family: Montserrat, sans-serif;
						cursor: pointer !important;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							text-decoration: none;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}

	}

	.nav-row {
		.col-12 {
			display: flex;
			-webkit-box-pack: end;
			justify-content: flex-end;

			.icons-nav {
				display: flex;
				align-items: center;
				margin-bottom: 0;

				@media (max-width:1200px) {
					position: absolute;
					right: 40px;
					top: 13px;
				}

				@media (max-width:767px) {
					position: absolute;
					right: 40px;
					top: -42px;
				}

				li {
					a {
						padding: 22px 1.2vW;

						&:hover {
							background-color: var(--main-nav-bg-affix-top-hover);
							color: var(--main-nav-font-color-hover);
						}
					}
				}

			}
		}
	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 1200px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			bottom: 10px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;


			}

			.nav-row {
				min-height: 0px !important;
			}

			.icon {
				position: absolute;
				bottom: 9px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);
							font-family: Montserrat, sans-serif;
							font-size: 1.0625rem;

							@media (max-width: 1200px) {
								color: var(--color-white);
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);

								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

	.mega-menu {
		ul {
			li {
				@media (max-width:1200px) {
					position: relative;
				}

				&:hover {


					.megamenu-bl {
						@media (min-width:1200px) {
							display: block;
						}

					}
				}

				a {
					&.active {
						color: red !important;
					}
				}

				.arrow {
					position: absolute;
					top: 0;
					right: 15px;
					height: 30px;
					width: 30px;
					text-align: center;
					line-height: 30px;
					cursor: pointer;
					display: none;

					@media (max-width:1200px) {
						display: block;
					}

					&:before {
						content: "+";
						position: absolute;
						left: 0;
						right: 0;
						color: #fff;
						font-size: 30px;
						margin: 0 auto;



					}

					&.open {
						&:before {
							content: "-";
							position: absolute;
							left: 0;
							right: 0;
							color: #fff;
							font-size: 30px;
							margin: 0 auto;



						}
					}
				}

				.megamenu-bl {

					display: none;

					&.menu-open {
						display: block;
					}

					.dropdown-list-wrapper {
						display: flex;
						position: absolute;
						left: 0;
						width: 100%;
						background: var(--color-primary);
						flex-wrap: wrap;
						padding: 20px;
						font-family: Montserrat, serif !important;

						@media (max-width:1200px) {
							position: inherit;
							flex-direction: column;
							padding: 0 0 0 20px;
						}

						li {
							.liUeber {
								color: #0095b6;
								text-transform: uppercase;
								border-bottom: 1px solid #0095b6;
								width: 100%;
								display: inline-block;
								padding-bottom: 10px;
								margin-bottom: 10px;
								font-weight: 600;
								padding-left: 0;
								pointer-events: none;

								&:before {
									display: none;
								}


							}

							a {
								color: var(--color-white);
								padding-left: 18px;
								position: relative;
								-webkit-transition: all .6s;
								transition: all .6s;
								margin: 5px 0;
								display: inline-block;

								&:hover {
									color: #f1c413;

									&:before {
										color: #f1c413;
									}
								}

								@media (max-width:1200px) {
									font-size: 15px;
								}
							}

							&.col-1-3 {
								width: 33.33%;
								padding: 0 10px;

								@media (max-width:1200px) {
									width: 100%;
								}

							}

							&.col-1-2 {
								width: 50%;
								padding: 0 10px;

								@media (max-width:1200px) {
									width: 100%;
								}
							}

							a {
								&:hover {
									text-decoration: none
								}

								&:before {
									content: "›";
									color: #fff;
									float: left;
									font-size: 30px;
									padding-top: 0;
									padding-left: 0;
									position: absolute;
									left: 0;
									top: 0;
									line-height: 20px;
									-webkit-transition: all .6s;
									transition: all .6s;
								}
							}
						}
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}