.home-offer-bl {
	height: 210px;
	width: 100%;
	background-size: cover;
	position: relative;
	display: block;
	margin: 15px 0;

	.offer-bl-box {
		position: absolute;
		left: 0;
		bottom: 10px;
		text-align: left;


		h3 {
			display: inline-block;
			background: #fea000;
			color: var(--color-white);
			font-size: 1.5rem;
			padding: 10px 25px;
			margin: 0;
			font-weight: bold;
			font-family: 'Montserrat', serif;
			font-weight: 700;
		}

		h4 {
			background: #0095b6;
			color: var(--color-white);
			font-size: 1.125rem;
			padding: 8px 25px;
			font-family: var(--font-family-main);
		}
	}

	&.bg-angebote {
		background-image: url(RESOURCE/img/bg-angebote.webp);
	}

	&.bg-angebote2 {
		background-image: url(RESOURCE/img/bg-angebote2.webp);

		h3 {
			background-color: #05314c;
		}
	}
}