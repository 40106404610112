.eyecatcher-wraper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	text-align: center;

	@media(max-width: 767px) {
		flex-direction: column;
		padding: 20px 0;
	}

	.eyecatcher-item {
		width: 25%;
		padding: 20px;
		min-height: 145px;
		border-right: 1px solid var(--color-primary);

		@media(max-width: 767px) {
			min-height: auto;
			border-right: 0;
			border-bottom: 1px solid var(--color-primary);
			width: 100%;

		}

		.fa {
			color: var(--color-primary);
			font-size: 30px;
		}

	}

	.eyecatcher-item:last-child {
		border: 0;
	}
}