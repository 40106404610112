.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
			color: var(--color-primary);
		}
	}

	.facts {

		ul {

			@media(max-width: 1199px) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			li {
				margin-bottom: 5px;

				@media(max-width: 1199px) {
					width: 50%;
					white-space: nowrap;
				}

				.icon-check-circle {
					content: "";
					background-image: url(RESOURCE/img/icon-circle-check.svg);
					height: 16px;
					width: 16px;
					background-size: contain;
					margin-right: 0px;
					background-repeat: no-repeat;
					position: relative;
					top: 3px;

				}

				.fa-arrow-up-right-from-square {
					content: "";
					background-image: url(RESOURCE/img/arrow-up-right-from-square-solid.svg);
					height: 16px;
					width: 16px;
					background-size: contain;
					margin-right: 0px;
					background-repeat: no-repeat;
					position: relative;
					top: 3px;

				}

				.fa-stack {
					display: none;
				}

				.fa {
					width: 30px;
					color: var(--color-primary);

					&.icon {
						img {
							max-width: 15px
						}

					}

					&.fa-hot-tub-person:before {
						content: "\f593";
					}

					&.fa-martini-glass-citrus:before {
						content: "\f561";
					}

					&.fa-balcony {
						content: "";
						background-image: url(RESOURCE/img/icon-balcony.png);
						height: 16px;
						width: 16px;
						background-size: contain;
						margin-right: 15px;
					}

					&.fa-tree:before {
						content: "\f1bb";

					}

					&.fa-closedPlot:before {
						content: "\f299";
						font-weight: 900;
						font-family: 'Font Awesome 5 Brands';
					}
				}

				.fa-stack {
					margin-left: -10px;
				}


			}
		}
	}

	.stars {
		color: var(--color-stars);
	}

}