.unit-properties-v1 {

	.widget-header {
		background: transparent;
		color: var(--color-secondary2);
		font-family: Montserrat, serif !important;
		text-transform: uppercase;
		font-weight: 700;
		border: none;
	}

	.widget-block {
		border: none;
	}

	.tick-li {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		@media (max-width:992px) {
			font-size: var(--font-size-md);
		}

		li {
			width: 33%;
			padding-left: 30px;
			padding-right: 15px;
			min-height: 30px;
			position: relative;

			@media (max-width:992px) {
				width: 50%;
			}

			@media (max-width:480px) {
				width: 100%;
			}

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}
	}

	.showLess {
		position: relative;

		&.showFull {
			.widget {
				display: block;
			}


			.hide-show-btn {

				&:after {
					content: "\f106";
				}
			}
		}

		.widget {
			display: none;
		}

		.first {
			display: block;
		}

		.hide-show-btn {
			position: absolute;
			bottom: -14px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--white);
			display: inline-block;
			border: 1px solid var(--btn-border-color-hide-show);
			font-size: var(--font-size-md);

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "\f107";
				margin-left: 10px;
				margin-left: 5px;
				font-family: fontawesome;
			}
		}
	}
}