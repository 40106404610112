.static-view {
	margin-top: var(--page-margin-top);

	a {
		font-weight: 700;
	}

	.btn {
		font-weight: 400;
	}

	.img-grid-bl {
		width: 70%;
		margin: 0 auto;
	}

	.list {
		list-style: disc;
		padding-left: 15px;
	}

	.list-type {
		list-style: disc;
		padding-left: 15px;
	}

	.height-100 {
		height: 100%;
	}

	.mb-30 {
		margin-bottom: 30px;
	}

	.xs-mb-30 {
		@media (max-width:767px) {
			margin-bottom: 30px;
		}
	}

	.grid-container {
		.grid-item {
			position: relative;
			overflow: hidden;
			border-radius: 4px;
			border: 0;

			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}

			.btn {

				width: 100%;
				text-align: center;
				vertical-align: bottom;
				color: var(--color-white);
				text-shadow: 1px 1px 10px rgba(2, 0, 0, 0.7);
				font-weight: 300;
				font-size: 27px;
				text-transform: uppercase;
				background: linear-gradient(to bottom, rgba(0, 52, 105, 0) 50%, rgba(0, 52, 105, 0.85) 95%, rgba(0, 52, 105, 0.85) 100%);
				transition: all ease-in-out 0.3s;
				padding-bottom: 25px;
				position: absolute;
				bottom: -2px;
				height: 105%;
				display: flex;
				align-items: end;
				justify-content: center;
			}
		}
	}

	&.anreise-v1 {
		img {
			@media (max-width:767px) {
				width: 100%;
			}

		}
	}

	&.parken-v1 {
		.icon-park {
			img {
				max-width: 350px;
			}
		}
	}

	&.urlaubscheckliste-v1 {
		.col-img {
			width: 130px;
		}
	}

	&.bildervom-v1 {
		.img-responsive {
			-o-object-fit: cover;
			object-fit: cover;
			width: 100%;
			position: absolute;
			top: 0;
			height: 100%;

		}

		.imgcols {
			margin-bottom: 30px;
			display: block;
			padding-top: 60%;
			position: relative;
		}
	}

	&.Panoramen-v1 {
		iframe {
			width: 100%;
		}
	}

	&.Dassind-v1 {
		img {
			width: 100%;
		}
	}

	&.team-v1 {
		.members-wraper {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			text-align: center;

			.member-col {
				width: 350px;
				margin-bottom: 20px;

				.member-thumb {
					width: 200px;
					height: 200px;
					border-radius: 50%;
					overflow: hidden;
					-moz-border-radius: 50%;
					-webkit-border-radius: 50%;
					border-radius: 50%;
					border: 8px solid #fdf4d7;
					margin: 0 auto 15px auto;

				}
			}
		}
	}

	&.offnungszeiten-v1 {
		.openings-hour {
			padding: 20px;

			&.bg-brown {
				background: #f3f2ee;
			}

			&.bg-lightblue {
				background: #def1f6;
			}
		}
	}

	&.eigentumer-v1,
	&.cafe-v1 {
		img {
			width: 100%;
		}
	}

	&.newsletter-v1 {
		img {
			&.img-small {
				width: 150px
			}

		}
	}

	&.tipps-v1 {
		.table-container {
			margin-bottom: 30px;
		}
	}

	&.jugendurlaub-v1 {
		.sport-bl {
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
	}

	&.kraniche-v1 {
		.video {
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 0;
			height: 0;
			overflow: hidden;
			margin-top: 30px;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.web-cam-img {
		object-fit: cover;
		width: 100%;
		height: auto;
	}

	.webcam-massage {
		text-align: center;
		color: red;
	}

	.webcam-div {
		position: relative;
	}

	.webcam-date {
		position: absolute;
		top: 0px;
		right: 0px;
		font-size: 16px;
		color: white;
		padding: 5px 5px;
		text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
	}
}