.ferienhausersection {
	h2 {
		a {


			&:hover {
				text-decoration: none
			}
		}
	}

	.f-mont {
		text-transform: uppercase;
	}

	.card {
		@media (max-width:992px) {
			margin-bottom: 20px;
		}

		.link {
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			top: 0;
			z-index: 5;
			height: 100%;
		}
	}
}