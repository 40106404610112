.weitereInformationen-v1 {
	.weitere-para {
		border-top: 1px solid #ccc;
		padding-top: 30px;
	}

	.witere-info-bl {
		text-align: left;
		/*	border-bottom: 1px solid #ccc;		
		padding-bottom: 30px;*/
		margin-bottom: 40px;
		display: none;
	}

	.arrow-dwon {
		border: solid;
		border-width: 0 0.05em 0.05em 0;
		display: inline-block;
		padding: 0.3em;
		cursor: pointer;
		transform: rotate(45deg);
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 25px;
		height: 25px;
		bottom: -40px;

		&.open {
			transform: rotate(225deg);
			bottom: -50px;
		}
	}

	.heading1 {
		position: relative;
		margin-bottom: 70px;
		text-transform: uppercase;
	}
}